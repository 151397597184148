<template>
  <div
    class="app-card-clickable traveler-card"
    tabindex="0"
    @click="$emit('click')"
    @keyup.enter="$emit('click')"
  >
    <p style="font-family: LuciferSans">
      {{ getFullName(traveler) }}
    </p>
    <p style="color: var(--color-primary)">{{ traveler.travelerType }}</p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'TravelerCard',
  props: { traveler: Object },
  computed: mapGetters('TitleModule', ['getFullName']),
}
</script>

<style scoped lang="scss">
.traveler-card {
  padding: 1.25rem 1rem;
  font-family: DDin;
  font-size: 15px;
}
</style>

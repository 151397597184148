<template>
  <div class="summary-header">
    <div class="summary-header-image-wrapper">
      <img
        v-if="!imageLoadFailed && imageUrl"
        class="summary-header-image"
        :src="imageUrl"
        alt="City"
        @error="imageLoadFailed = true"
      />
      <div
        v-if="!imageLoadFailed && imageUrl"
        class="summary-header-image-bg"
      ></div>
      <div v-else class="summary-header-image-placeholder">
        <IconPlaceholderCityDark
          style="position: relative; top: -9%; right: -50px; color: white"
        />
      </div>
    </div>
    <div class="container-xs summary-header-content">
      <div style="display: flex">
        <AppNavButton icon="back" @click="$emit('back')" />
        <AppNavButton
          icon="close"
          style="margin-left: auto"
          @click="$emit('exit')"
        />
      </div>
      <div
        style="
          align-self: flex-end;
          font-family: GTSuper;
          text-shadow: var(--text-shadow);
        "
      >
        <p style="font-size: 46px; line-height: 55px">
          {{ location.display.city
          }}<span v-if="location.display.regionCountry">,</span>
        </p>
        <p
          v-if="location.display.regionCountry"
          style="font-size: 24px; line-height: 28px"
        >
          {{ location.display.regionCountry }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import AppNavButton from '../../AppNavButton.vue'
import IconPlaceholderCityDark from '../../icons/placeholders/IconPlaceholderCityDark.vue'

export default {
  name: 'SummaryHeader',
  components: { AppNavButton, IconPlaceholderCityDark },
  props: { location: Object },
  data() {
    return {
      imageUrl: this.location.imageUrl,
      imageLoadFailed: false,
    }
  },
}
</script>

<style scoped lang="scss">
.summary-header {
  --min-height: 250px;
  min-height: var(--min-height);
  position: sticky;
  overflow: hidden;
}
.summary-header-content {
  position: relative;
  color: white;
  min-height: var(--min-height);
  padding-top: 2rem;
  padding-bottom: 2rem;
  @include s {
    padding-bottom: 4rem;
  }
  display: grid;
  grid-template-rows: auto 1fr;
  gap: 2rem;
}
.summary-header-image-wrapper {
  height: 100%;
  & > * {
    width: 100%;
    height: 100%;
    position: absolute;
  }
}
.summary-header-image {
  object-fit: cover;
  object-position: center;
  @include m {
    filter: blur(4px);
  }
}
.summary-header-image-placeholder {
  background: var(--color-icon-background-dark);
  display: flex;
  align-items: center;
  justify-content: center;
}
.summary-header-image-bg {
  position: absolute;
  background: #0004;
  background: linear-gradient(#0000 30%, #0008 100%);
}
</style>

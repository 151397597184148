<template>
  <AppButtonCard :label="label || 'Add'" @click="$emit('click')">
    <div class="promo-code-card">
      <IconPromoCode />
      <p>Promo Code</p>
    </div>
  </AppButtonCard>
</template>

<script>
import AppButtonCard from '../AppButtonCard.vue'
import IconPromoCode from '../icons/payments/IconPromoCode.vue'

export default {
  name: 'AddPromoCodeCard',
  props: {
    label: String,
  },
  components: { AppButtonCard, IconPromoCode },
}
</script>

<style scoped lang="scss">
.promo-code-card {
  color: #a0a0a0;
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 0.7rem;
  align-items: center;
}
</style>

<template>
  <div>
    <AppLabel label="PAYMENT METHOD" />
    <PaymentCard
      v-if="activePayment"
      :payment="activePayment"
      @click="visitorSearchGuard(() => $router.push('/search/payments'))"
    />
    <AddPaymentCard
      v-else
      :data-error="!validData.payment && showIncompleteData"
      @click="visitorSearchGuard(() => $router.push('/search/payments'))"
    />
    <AddPromoCodeCard
      :label="getActive.discount && getActive.discount.name"
      @click="visitorSearchGuard(() => $router.push('/search/promo-code'))"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import AppLabel from '../../AppLabel.vue'
import PaymentCard from '../../payments/PaymentCard.vue'
import AddPaymentCard from '../../payments/AddPaymentCard.vue'
import AddPromoCodeCard from '../../payments/AddPromoCodeCard.vue'

export default {
  name: 'SummaryPayments',
  components: {
    PaymentCard,
    AppLabel,
    AddPaymentCard,
    AddPromoCodeCard,
  },
  computed: {
    ...mapState('SearchState', ['showIncompleteData']),
    ...mapGetters('SearchState', ['activePayment', 'validData', 'getActive']),
  },
  methods: mapActions('PersistModule', ['visitorSearchGuard']),
}
</script>

<style scoped lang="scss"></style>

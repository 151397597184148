<template>
  <div>
    <AppLabel :label="travelerCount > 1 ? 'PASSENGERS' : 'PASSENGER'" />
    <UserCard
      v-if="validData.user"
      @click="
        visitorSearchGuard(() => $router.push('/search/travelers/user-details'))
      "
    />
    <AppButtonCard
      v-else
      label="Complete your details"
      text="Adult"
      :data-error="!validData.user && showIncompleteData"
      @click="
        visitorSearchGuard(() => $router.push('/search/travelers/user-details'))
      "
    />
    <template v-for="travelers in travelerTypes">
      <TravelerCard
        v-for="traveler in travelers"
        :key="traveler.id"
        :traveler="traveler"
        @click="
          visitorSearchGuard(() =>
            $router.push(`/search/travelers/edit/${traveler.id}`),
          )
        "
      />
    </template>
    <AppButtonCard
      v-for="traveler in addTravelerTypes"
      :key="traveler.type"
      label="Add"
      :text="traveler.text"
      :data-error="!validData.travelers && showIncompleteData"
      @click="visitorSearchGuard(traveler.onClick)"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import AppButtonCard from '../../AppButtonCard.vue'
import AppLabel from '../../AppLabel.vue'
import UserCard from '../passengers/UserCard.vue'
import TravelerCard from '../passengers/TravelerCard.vue'

export default {
  name: 'SummaryPassengers',
  components: {
    AppButtonCard,
    AppLabel,
    UserCard,
    TravelerCard,
  },
  computed: {
    ...mapState('SearchState', ['showIncompleteData']),
    ...mapGetters('SearchState', [
      'hasTravelers',
      'activeTravelers',
      'validData',
      'travelerCount',
    ]),
    travelerTypes() {
      const { adults, children, infants } = this.activeTravelers
      return [adults, children, infants]
    },
    addTravelerTypes() {
      const travelers = [
        { type: 'adults', text: 'Adult', query: 'adult' },
        { type: 'children', text: 'Child', query: 'child' },
        { type: 'infants', text: 'Infant', query: 'infant' },
      ]
      return travelers.reduce((a, { type, text, query }) => {
        if (this.hasTravelers[type]) return a
        const onClick = () =>
          this.$router.push(`/search/travelers/select?${query}`)
        return [...a, { type, text, onClick }]
      }, [])
    },
  },
  methods: mapActions('PersistModule', ['visitorSearchGuard']),
}
</script>

<style scoped lang="scss"></style>

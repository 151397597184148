<template>
  <div class="app-negative-margin-top summary">
    <SummaryHeader
      :location="getActive.trip.arrivalLocation"
      @exit="$refs.cancelTripModal.show()"
      @back="onBack"
    />
    <div class="container-xs summary-content-wrapper">
      <div class="summary-content">
        <SummaryFlight
          v-if="METADATA.hasFlights"
          :flight-direction="getActive.flight.departureFlight"
          :info-price="oneWayFlightPrice"
          info-label="More Flights"
          label="DEPARTURE FLIGHT"
          show-card
          show-info
          @click="$router.push('/search/flights')"
        />
        <SummaryHotel
          v-if="METADATA.hasHotels"
          :hotel="getActive.hotel"
          :info-price="getActive.hotelPrice"
          :room="getActive.room"
          info-label="Change Hotel"
          show-card
          show-info
          @click="$router.push('/search/hotels')"
        />
        <SummaryFlight
          v-if="METADATA.hasFlights"
          :flight-direction="getActive.flight.returnFlight"
          :info-price="oneWayFlightPrice"
          info-label="More Flights"
          label="RETURN FLIGHT"
          show-card
          show-info
          @click="$router.push('/search/flights')"
        />
        <SummaryPassengers />
        <SummaryPayments />
        <div class="summary-content-cta">
          <div
            style="
              display: grid;
              grid-template-columns: auto auto;
              row-gap: 0.25rem;
              column-gap: 1rem;
              justify-content: center;
              align-items: center;
            "
          >
            <p style="font-family: LuciferSans; font-size: 13px">TOTAL</p>
            <p
              style="
                font-family: SpaceMono;
                font-size: 25px;
                justify-self: flex-end;
              "
            >
              {{ $formatPrice(getActive.totalPrice) }}
            </p>
            <template v-if="getActive.discount">
              <p
                style="
                  font-style: italic;
                  font-size: 15px;
                  color: #50555c;
                  font-family: DDin;
                "
              >
                Saved:
              </p>

              <p
                style="
                  font-style: italic;
                  font-size: 15px;
                  color: #50555c;
                  font-family: DDin;
                  justify-self: flex-end;
                "
              >
                {{ getActive.discount.saved }}
              </p>
            </template>
          </div>
          <XButton
            id="submit"
            type="large"
            style="font-size: 19px; width: 100%; position: relative"
            :is-loading="isLoading"
            @click="visitorSearchGuard(onBooking)"
            >{{ ctaText }}
          </XButton>
        </div>
      </div>
    </div>
    <InfoModal
      ref="cancelTripModal"
      title="ARE YOU SURE YOU WANT TO LEAVE?"
      text="We're working on preparing your best trip yet!"
      cta="YES, I'M SURE"
      alt-cta="CANCEL"
      hide-close-button
      @alt-cta="$refs.cancelTripModal.hide()"
      @cta="onExit"
    />
    <InfoModal
      ref="confirmBookingModal"
      title="ARE YOU SURE YOU WANT TO BOOK THIS TRIP?"
      cta="YES, I'M SURE"
      alt-cta="CANCEL"
      hide-close-button
      @alt-cta="$refs.confirmBookingModal.hide()"
      @cta="onBookingConfirm"
    />
    <InfoModal
      ref="priceChangedModal"
      title="Trip price changed."
      :text="priceChangedModalText"
    />
    <LoadingModalFullScreen
      ref="revalidationLoadingModal"
      loader="plane"
      non-closable
      text="Gathering Trip Information"
    />
    <InfoModal
      ref="revalidationErrorModal"
      text="Itinerary revalidation failed."
      cta="Try again"
      @cta="$refs.revalidationErrorModal.hide()"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import InfoModal from '../../components-rf/modals/InfoModal.vue'
import LoadingModalFullScreen from '../../components-rf/modals/LoadingModalFullScreen.vue'
import SummaryFlight from '../../components-rf/search/summary/SummaryFlight.vue'
import SummaryHeader from '../../components-rf/search/summary/SummaryHeader.vue'
import SummaryHotel from '../../components-rf/search/summary/SummaryHotel.vue'
import SummaryPassengers from '../../components-rf/search/summary/SummaryPassengers.vue'
import SummaryPayments from '../../components-rf/search/summary/SummaryPayments.vue'
import XButton from '../../components/common/global/XButton.vue'
import { FLIGHT } from '../../constants/FLIGHT'
import { SEARCH } from '../../constants/SEARCH'
import { appAnalytics } from '../../services/analytics/analytics'

export default {
  name: 'TripSummary',
  components: {
    SummaryHeader,
    SummaryFlight,
    SummaryHotel,
    SummaryPassengers,
    SummaryPayments,
    XButton,
    InfoModal,
    LoadingModalFullScreen,
  },
  created() {
    if (this.METADATA.hasHotels) {
      const { hotelSelection } = this.getActive
      if (!hotelSelection.hasDetails) {
        this.isLoading = true
        hotelSelection.getDetails().finally(() => (this.isLoading = false))
      }
    }
    this.initPaymentMethod()
  },
  mounted() {
    appAnalytics.page({ title: 'search_summary_screen_view' })
  },
  data() {
    return {
      isLoading: false,
      priceChangedModalText: '',
    }
  },
  computed: {
    ...mapGetters('SearchState', [
      'getActive',
      'activePrice',
      'searchType',
      'validData',
    ]),
    METADATA() {
      const meta = SEARCH.meta[this.searchType]
      return {
        hasHotels: meta.hasHotels,
        hasFlights: meta.hasFlights,
      }
    },
    oneWayFlightPrice() {
      if (!this.getActive.flight) return
      return this.getActive.flightPrice / FLIGHT.ONE_WAY_PRICE_DIVIDER
    },
    ctaText() {
      return this.isLoading ? 'GETTING HOTEL DETAILS' : 'BOOK TRIP'
    },
  },
  methods: {
    ...mapActions('PersistModule', ['visitorSearchGuard']),
    ...mapActions('SearchState', [
      'revalidateItinerary',
      'validateData',
      'abandonTrip',
      'initPaymentMethod',
    ]),
    onExit() {
      appAnalytics.track('search_summary_btn_close')
      this.abandonTrip()
      this.$router.push('/search/form')
    },
    onBack() {
      const isPackageSearch = this.searchType === SEARCH.TYPE.PACKAGE
      const route = isPackageSearch ? '/search/shortlist' : '/search/form'
      appAnalytics.track('search_summary_btn_back')
      this.$router.push(route)
    },
    async onBooking() {
      appAnalytics.track('search_summary_btn_book_trip')
      const isValid = await this.validateData()
      if (!isValid) return
      this.$refs.confirmBookingModal.show()
    },
    async onBookingConfirm() {
      const { priceChanged, err } = await this.revalidate()
      if (err) this.$refs.revalidationErrorModal.show()
      else if (priceChanged) this.$refs.priceChangedModal.show()
      else this.$router.push('/search/reservation')
    },
    revalidate() {
      this.$refs.revalidationLoadingModal.show()
      return this.revalidateItinerary()
        .then(
          ({ priceChanged, flightPriceDifference, hotelPriceDifference }) => {
            if (!priceChanged) return { priceChanged }

            const text = []
            if (flightPriceDifference)
              text.push(
                `flight price changed by ${this.$formatPrice(
                  flightPriceDifference,
                )}`,
              )
            if (hotelPriceDifference)
              text.push(
                `hotel price changed by ${this.$formatPrice(
                  hotelPriceDifference,
                )}`,
              )
            const rawMessage = text.join(', and ') + '.'
            this.priceChangedModalText =
              rawMessage.charAt(0).toUpperCase() + rawMessage.slice(1)

            return { priceChanged }
          },
        )
        .catch(() => ({ err: true }))
        .finally(() =>
          setTimeout(() => this.$refs.revalidationLoadingModal?.hide?.()),
        )
    },
  },
}
</script>

<style scoped lang="scss">
.summary-content-wrapper {
  position: relative;
  @include s {
    top: -3rem;
  }
}
.summary-content {
  --border-radius: 25px;
  --padding: 1rem;
  & > div {
    display: grid;
    gap: 0.75rem;
  }
  padding-top: 1.5rem;
  @include s {
    padding-left: var(--padding);
    padding-right: var(--padding);
    box-shadow: var(--shadow-app);
    border-radius: var(--border-radius);
  }
  background: #fcfcfc;
  display: grid;
  gap: 1.5rem;
}
.summary-content-cta {
  border-top: 2px solid var(--color-secondary);
  margin-left: calc(-1 * var(--padding));
  margin-right: calc(-1 * var(--padding));
  padding: var(--padding);
  @include s {
    background: white;
    border-bottom-right-radius: var(--border-radius);
    border-bottom-left-radius: var(--border-radius);
  }
}
</style>
